import React from 'react';

import AdminLayout from '../../adminlayout/index';

import SEO from 'components/seo/index';
// import Side from 'layout/sidebaritem';

import UploadplantationDownload from 'components/admin/uploadplantation';

const Uploadplantation = () => {
  return (
    <>
      <SEO title='Forests By Heartfulness admin' />
      <AdminLayout>
      <div>
        <UploadplantationDownload />
      </div>
      </AdminLayout>
    </>
  );
};

export default Uploadplantation;
