import React, { useEffect, useState } from "react";
import "assets/styles/pages/sidebar.scss";
import { Link, navigate } from "gatsby";
import treeicon from "assets/images/admin/tree-icon.png";
import breadcumbarrow from "assets/images/admin/breadcumb-arrow-left.png";
import plantedBy from "assets/images/admin/plantedBy.png";
import plantedOn from "assets/images/admin/plantedOn.svg";
import projectViewGreen from "assets/images/admin/project-view-green.png";

import { useForm } from "react-hook-form";
import moment from "moment";

import AdminService from "services/admin/admin.service";
import { Modal, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import PlantationListDetails from "services/plantation/plantation.service";
import { saveAs } from "file-saver";
import * as xlsx from "xlsx";
import _ from "lodash";

const ErrorJson = [
  {
    project: "Project Name is missing",
    plantation_Registrar_Name: "Plantation Registrar Name missing in ",
    planter_Name: "Planter Name is missing in ",
    planter_Email: "Planter Email missing in ",
    Planter_Phone_Number: "Planter Phone Number Missing in ",
    plantation_Date: "Plantation Date is Missing in ",
    city: "Plantation city is Missing in ",
    state: "Plantation state is Missing in ",
    pincode: "Plantation pincode is Missing in ",
    tracking_data: "is Missing Tracking Data",
    tracking_id: "is Missing Tracking Id",
    geoLocation: " is Missing GeoLocation",
    species_record: " is Missing species Record",
    species_name: " is Missing species Name",
    species_count: " is Missing species count",
    species_height: " is Missing species height",
    species_girth: " is Missing species girth",
    species_data: " is Missing Species Data",
    plant_height: " ( Plant height should be greater than 0 )",
    plant_girth: " ( Plant girth should be greater than 0 )",
    phone_number_length:
      " ( Phone Number should be greater than equal to 10 digits )",
    phone_number_length_exceeded:
      "  ( Phone Number should be less than equal to 13 digits )",
  },
];
export default function Uploadplantation(props) {
  const errorMessage = ErrorJson[0];
  const adminApi = new AdminService();
  const ds = new PlantationListDetails();
  const [PlantationDetails, setPlantationDetails] = useState();
  const [showPlantationDetails, setShowPlantationDetails] = useState(false);
  const [parsedPlantationDetails, setParsedPlantationDetails] = useState();
  const [errorMsg, setErrorMsg] = useState([]);
  const [showErrorBox, setShowErrorBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState("no");
  // const [isChanged, setIsChanged] = useState(false);

  const showError = () => {
    if (errorMsg.length > 0) {
      setShowPlantationDetails(false);
      setShowErrorBox(true);
    } else {
      setShowPlantationDetails(true);
      setShowErrorBox(false);
    }
  };

  const onSubmit = async (data) => {
    showError();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  const postPlantationDetails = () => {
    setIsLoading(true);

    const newData = parsedPlantationDetails?.plantationExcelUploadRequest?.map(
      (item) => {
        return {
          ...item,
          plantationDate: {
            seconds: Math.floor(
              moment(item?.plantationDate?.seconds).valueOf() / 1000
            ),
            nanos: item?.plantationDate?.nanos,
          },
          trackingExcelDetails: item.trackingExcelDetails.map((tracingData) => {
            return {
              ...tracingData,
              speciesExcelDetails: tracingData.speciesExcelDetails.map(
                (speciesData) => {
                  return {
                    speciesId: speciesData.species,
                    speciesCount: speciesData.speciesCount,
                    attributeMap: {
                      height: speciesData?.height,
                      girth: speciesData?.girth,
                    },
                  };
                }
              ),
            };
          }),
        };
      }
    );
    const payloadToSend = { plantationExcelUploadRequest: newData };
    ds.postPlantationData(payloadToSend).then((res) => {
      if (res?.data?.isUploaded === true) {
        setIsLoading(false);
        toast.success("Plantation Data Uploaded Successfully");
        setTimeout(() => {
          navigate("/admin/admindashboardstatus");
        }, 1000);
      } else {
        setIsLoading(false);
        const binaryString = window.atob(res.data.errorReport);
        const byteArray = Uint8Array.from(binaryString, (value) =>
          value.charCodeAt(0)
        );
        const blob = new Blob([byteArray], {
          type: "application/vnd.ms-excel;charset=utf-8",
        });
        saveAs(blob, "plantation_error-report.xlsx");
      }
    });
  };

  const downloadPlantation = () => {
    //condition checking to change state from true to false and vice versa
    adminApi
      .downloadPlantationExcel()
      .then((data) => {
        if (data.status === 200) {
          const binaryString = window.atob(data.data.fileContent);
          const byteArray = Uint8Array.from(binaryString, (value) =>
            value.charCodeAt(0)
          );

          const blob = new Blob([byteArray], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          saveAs(blob, "plantation_sample_template.xlsx");
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch(() => {});
  };

  const readUploadFile = (e) => {
    while (errorMsg.length > 0) {
      errorMsg.pop();
    }

    e.preventDefault();
    let plantationJson = [];
    let plantationExcelUploadRequest = [];

    if (e.target.files.length >= 1) {
      if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: "array", cellDates: true });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          let json = xlsx.utils.sheet_to_json(worksheet);
          let plandationDetails = _.groupBy(json, "PlantationId");

          var jsonObj = {};
          var addressArray = [];

          let j = 0;
          for (var planitem in plandationDetails) {
            var plantationRecords = plandationDetails[planitem];
            var trackingArray = [];
            var projectName = plantationRecords[0]?.Project;
            var project = projectName?.split("-")?.map((item) => item.trim());
            var stateName = plantationRecords[0]?.State;
            var state = stateName?.split("-")?.map((item) => item.trim());

            if (projectName === undefined || null) {
              setErrorMsg([errorMessage.project]);
            } else {
              if (
                plantationRecords?.[0]?.PlantationRegistrarName === undefined ||
                null
              ) {
                errorMsg.push(
                  errorMessage.plantation_Registrar_Name + projectName
                );
              }
              if (plantationRecords?.[0]?.PlanterName === undefined || null) {
                errorMsg.push(errorMessage.planter_Name + projectName);
              }
              if (plantationRecords?.[0]?.PlanterEmail === undefined || null) {
                errorMsg.push(errorMessage.planter_Email + projectName);
              }
              if (
                plantationRecords?.[0]?.PlanterPhoneNumber === undefined ||
                null
              ) {
                errorMsg.push(errorMessage.Planter_Phone_Number + projectName);
              }
              if (
                plantationRecords?.[0]?.PlanterPhoneNumber?.toString().length <
                10
              ) {
                errorMsg.push(
                  projectName +
                    " has phone number of less than 10 digits." +
                    errorMessage.phone_number_length
                );
              }
              if (
                plantationRecords?.[0]?.PlanterPhoneNumber?.toString().length >
                13
              ) {
                errorMsg.push(
                  projectName +
                    " has phone number of greater than 13 digits." +
                    errorMessage.phone_number_length_exceeded
                );
              }
              if (
                plantationRecords?.[0]?.PlantationDate === undefined ||
                null
              ) {
                errorMsg.push(errorMessage.plantation_Date + projectName);
              }
              if (plantationRecords?.[0]?.City === undefined || null) {
                errorMsg.push(errorMessage.city + projectName);
              }
              if (state?.[0] === undefined || null) {
                errorMsg.push(errorMessage.state + projectName);
              }
              if (plantationRecords?.[0]?.Pincode === undefined || null) {
                errorMsg.push(errorMessage.pincode + projectName);
              } else {
                let formData = {
                  plantationRegistrarName:
                    plantationRecords?.[0]?.PlantationRegistrarName,
                  projectName: project?.[0],
                  projectId: Number(project?.[1]),
                  planterName: plantationRecords?.[0]?.PlanterName,
                  planterEmailAddress: plantationRecords?.[0]?.PlanterEmail,
                  planterPhoneNumber:
                    plantationRecords?.[0]?.PlanterPhoneNumber?.toString(),
                  plantationDate: {
                    seconds: plantationRecords?.[0]?.PlantationDate,
                    nanos: 0,
                  },
                  attributeMap: {
                    city: plantationRecords?.[0]?.City,
                    state: state?.[0],
                    country: "India",
                    pincode: plantationRecords?.[0]?.Pincode?.toString(),
                    stateCode: state?.[1],
                  },
                };

                addressArray.push(formData);

                jsonObj.plantationExcelUploadRequest = addressArray;

                let trackingDetails = _.groupBy(
                  plantationRecords,
                  "TrackingId"
                );

                let i = 0;
                for (var item in trackingDetails) {
                  var trackingRecords = trackingDetails[item];

                  if (
                    !trackingRecords?.[0]?.TrackingId &&
                    !trackingRecords?.[0]?.GeoLocation
                  ) {
                    setErrorMsg([
                      projectName + " " + errorMessage.tracking_data,
                    ]);
                  } else {
                    if (
                      trackingRecords?.[0]?.TrackingId === undefined ||
                      null
                    ) {
                      errorMsg.push([
                        projectName + " " + errorMessage.tracking_id,
                      ]);
                    }
                    if (
                      trackingRecords?.[0]?.GeoLocation === undefined ||
                      null
                    ) {
                      errorMsg.push([
                        projectName +
                          " " +
                          "with tracking id " +
                          trackingRecords?.[0]?.TrackingId +
                          errorMessage.geoLocation,
                      ]);
                    } else {
                      if (
                        JSON.stringify(
                          trackingRecords?.[0]?.GeoLocation
                        )?.includes(",") === false
                      ) {
                        setErrorMsg([
                          "Geo Location For " +
                            projectName +
                            " " +
                            "with tracking id " +
                            trackingRecords?.[0]?.TrackingId +
                            " is Invalid",
                        ]);
                      } else {
                        if (
                          trackingRecords?.[0]?.GeoLocation?.split(",")?.map(
                            (item) => item.trim()
                          ).length !== 2
                        ) {
                          errorMsg.push([
                            "Geo Location For " +
                              projectName +
                              " " +
                              "with tracking id " +
                              trackingRecords?.[0]?.TrackingId +
                              " is Invalid",
                          ]);
                        } else {
                          let trackingData = {
                            geoLocation: trackingRecords?.[0]?.GeoLocation,
                          };

                          trackingArray.push(trackingData);
                          var speciesArray = [];

                          for (var speciesRecord in trackingRecords) {
                            var speciesName =
                              trackingRecords[speciesRecord]?.SpeciesName;
                            var species = speciesName
                              ?.split(" - ")
                              ?.map((item) => item.trim());

                            if (
                              !species?.[0] &&
                              !trackingRecords[speciesRecord]?.SpeciesCount
                            ) {
                              setErrorMsg([
                                projectName +
                                  " " +
                                  "with tracking id " +
                                  trackingRecords?.[0]?.TrackingId +
                                  errorMessage.species_data,
                              ]);
                            } else {
                              if (species?.[0] === undefined || null) {
                                setErrorMsg([
                                  projectName +
                                    " " +
                                    "with tracking id " +
                                    trackingRecords?.[0]?.TrackingId +
                                    errorMessage.species_name,
                                ]);
                              } else {
                                if (
                                  trackingRecords[speciesRecord]
                                    ?.SpeciesCount === undefined ||
                                  null
                                ) {
                                  setErrorMsg([
                                    projectName +
                                      " " +
                                      "with tracking id " +
                                      trackingRecords?.[0]?.TrackingId +
                                      " and species name " +
                                      species?.[0] +
                                      errorMessage.species_count,
                                  ]);
                                }
                                if (
                                  trackingRecords[speciesRecord]?.Height ===
                                    undefined ||
                                  null
                                ) {
                                  errorMsg.push([
                                    projectName +
                                      " " +
                                      "with tracking id " +
                                      trackingRecords?.[0]?.TrackingId +
                                      " and species name " +
                                      species?.[0] +
                                      errorMessage.species_height,
                                  ]);
                                }
                                if (
                                  trackingRecords[speciesRecord]?.Height === 0
                                ) {
                                  errorMsg.push([
                                    projectName +
                                      " " +
                                      "with tracking id " +
                                      trackingRecords?.[0]?.TrackingId +
                                      " and species name " +
                                      species?.[0] +
                                      " has height as 0." +
                                      errorMessage.plant_height,
                                  ]);
                                }

                                if (
                                  trackingRecords[speciesRecord]?.Girth ===
                                    undefined ||
                                  null
                                ) {
                                  errorMsg.push([
                                    projectName +
                                      " " +
                                      "with tracking id " +
                                      trackingRecords?.[0]?.TrackingId +
                                      " and species name " +
                                      species?.[0] +
                                      errorMessage.species_girth,
                                  ]);
                                }
                                if (
                                  trackingRecords[speciesRecord]?.Girth === 0
                                ) {
                                  errorMsg.push([
                                    projectName +
                                      " " +
                                      "with tracking id " +
                                      trackingRecords?.[0]?.TrackingId +
                                      " and species name " +
                                      species?.[0] +
                                      " has girth as 0." +
                                      errorMessage.plant_girth,
                                  ]);
                                } else {
                                  let speciesData = {
                                    species: Number(
                                      speciesName?.split(" - ").pop()
                                    ),
                                    speciesName: species?.[0],
                                    speciesCount:
                                      trackingRecords[speciesRecord]
                                        ?.SpeciesCount,
                                    height:
                                      trackingRecords[speciesRecord]?.Height +
                                      "",
                                    girth:
                                      trackingRecords[speciesRecord]?.Girth +
                                      "",
                                  };
                                  speciesArray.push(speciesData);
                                }
                              }
                            }
                          }
                          trackingArray[i].speciesExcelDetails = speciesArray;
                          i = i + 1;
                        }
                      }
                    }
                  }
                }
                jsonObj.plantationExcelUploadRequest[j].trackingExcelDetails =
                  trackingArray;
                j = j + 1;
              }
            }
          }

          setIsUploading("no");
          setPlantationDetails(JSON.stringify(jsonObj));
          // };
          // console.log(JSON.stringify(jsonObj));
          /*let formData = {
          "plantationRegistrarName": json[0].PlanterRegistratorName,
          "projectId": json[0].Project,
          "planterName": json[0].PlanterName,
          "planterEmailAddress": json[0].PlanterEmail,
          "planterPhoneNumber": json[0].PlanterPhoneNumber,
          "city": json[0].City,
          "pinCode": json[0].Pincode,
          "plantationDate": json[0].PlantationDate
        }*/
          /*
                    plantation.push( jsonArg1 );
                    plantationJson['plantation'] = plantation;
                    //plantationJson['plantations'] = plantation;
                    console.log(JSON.parse(JSON.stringify(plantationJson)));
                    
                     json = _.groupBy(json, 'TrackingId')
                    console.log(json);*/
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      }
    }
  };

  useEffect(() => {
    if (PlantationDetails) {
      setParsedPlantationDetails(JSON.parse(PlantationDetails));
    }
  }, [PlantationDetails]);

  const emptyUploadedFileData = (e) => {
    while (errorMsg.length > 0) {
      errorMsg.pop();
    }
    e.target.value = "";
    setShowErrorBox(false);
    setShowPlantationDetails(false);
  };

  return (
    <>
      {isLoading ? (
        <Modal show={isLoading} centered className="modal-con">
          <div className="modal-con">
            <Spinner style={{ textAlign: "center" }} animation="border" />
          </div>
        </Modal>
      ) : (
        <>
          <div className="sidebar-content-wrapper">
            <div className="admin-breadcumb-item">
              <span>
                <Link className="" to="/admin/dashboardview/">
                  <img src={treeicon} alt="treeicon" />
                </Link>
              </span>

              <span>
                <img src={breadcumbarrow} alt="arrowicon" />
              </span>

              <h3> Upload Plantation </h3>
            </div>

            <div className="white-iitiative-wrapper">
              <div className="">
                <h3 className="sub-heading-title">Upload Plantation</h3>
              </div>
              <div className="text-right">
                <button
                  className="btn btn-viewmore save-width"
                  onClick={downloadPlantation}
                >
                  Download Sample Excel
                </button>
              </div>
              <div className="register-plantation">
                <div className="container">
                  <div className="row">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="fc-wrapper">
                            <label htmlFor="name" className="form-label">
                              Upload File <i className="hastric-color">*</i>
                            </label>
                            <input
                              type="file"
                              accept=".xls,.xlsx"
                              name="upload"
                              className="form-control "
                              id="upload"
                              defaultValue={props.upload}
                              {...register("upload", {
                                required: "Upload Plantation Excel Sheet",
                              })}
                              onClick={(e) => emptyUploadedFileData(e)}
                              onChange={(e) => {
                                setShowErrorBox(false);
                                setShowPlantationDetails(false);

                                if (e.target.files.length >= 1) {
                                  setIsUploading("yes");
                                  readUploadFile(e);
                                }
                              }}
                              placeholder=""
                            />
                            {errors.upload && (
                              <span className="form-error">
                                {errors.upload.message}
                              </span>
                            )}

                            {isUploading === "yes" && (
                              <h6>Plantation validation In Progress</h6>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="text-right">
                        <Link
                          to="/admin/admindashboardstatus"
                          className="btn btn-cancel mg-right10 margin-btm-10"
                        >
                          Cancel
                        </Link>

                        {/* <Link to='/admin/projectstatus' className='btn btn-viewmore save-width'>
                      Save
                    </Link> */}
                        <button
                          className="btn btn-viewmore save-width"
                          disabled={isUploading === "yes" ? "disabled" : ""}
                        >
                          Upload
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {!showErrorBox &&
                showPlantationDetails &&
                parsedPlantationDetails?.plantationExcelUploadRequest?.map(
                  (item) => {
                    const { trackingExcelDetails } = item;
                    return (
                      <div className="tracking-card-container">
                        <div>
                          <div className="dis-in-planed">
                            <span className="dis-image-item">
                              <img src={plantedBy} alt="treeRightIconGreen" />
                            </span>
                            <h5 className="planed-text">
                              Plantation Registrar Name
                            </h5>
                            <span className="planed-text">
                              {item?.plantationRegistrarName}
                            </span>
                          </div>
                          <div className="dis-in-planed">
                            <span className="dis-image-item">
                              <img src={plantedBy} alt="treeRightIconGreen" />
                            </span>
                            <h5 className="planed-text">Planter Name</h5>
                            <span className="planed-text">
                              {item?.planterName}
                            </span>
                          </div>
                          <div className="dis-in-planed">
                            <span className="dis-image-item">
                              <img src={plantedBy} alt="treeRightIconGreen" />
                            </span>
                            <h5 className="planed-text">
                              Planter Email Address
                            </h5>
                            <h5 className="planed-text">
                              {item?.planterEmailAddress}
                            </h5>
                          </div>
                          <div className="dis-in-planed">
                            <span className="dis-image-item">
                              <img src={plantedBy} alt="treeRightIconGreen" />
                            </span>
                            <h5 className="planed-text">Phone Number</h5>
                            <h5 className="planed-text">
                              {item?.planterPhoneNumber}
                            </h5>
                          </div>
                          <div className="dis-in-planed">
                            <span className="dis-image-item">
                              <img
                                src={projectViewGreen}
                                alt="treeRightIconGreen"
                              />
                            </span>
                            <h5 className="planed-text">City</h5>
                            <h5 className="planed-text">
                              {item?.attributeMap?.city}
                            </h5>
                          </div>
                          <div className="dis-in-planed">
                            <span className="dis-image-item">
                              <img
                                src={projectViewGreen}
                                alt="treeRightIconGreen"
                              />
                            </span>
                            <h5 className="planed-text">City</h5>
                            <h5 className="planed-text">
                              {item?.attributeMap?.state}
                            </h5>
                          </div>
                          <div className="dis-in-planed">
                            <span className="dis-image-item">
                              <img
                                src={projectViewGreen}
                                alt="treeRightIconGreen"
                              />
                            </span>
                            <h5 className="planed-text">Pin Code</h5>
                            <h5 className="planed-text">
                              {item?.attributeMap?.pincode}
                            </h5>
                          </div>
                          <div className="dis-in-planed">
                            <span className="dis-image-item">
                              <img src={plantedOn} alt="treeRightIconGreen" />
                            </span>
                            <h5 className="planed-text">Project Name</h5>
                            <h5 className="planed-text">{item?.projectName}</h5>
                          </div>
                          <div className="dis-in-planed">
                            <span className="dis-image-item">
                              <img src={plantedOn} alt="treeRightIconGreen" />
                            </span>
                            <h5 className="planed-text">Plantation Date</h5>
                            <h5 className="planed-text">
                              {moment(item?.plantationDate?.seconds).format(
                                "ddd, MMM D YYYY"
                              )}
                            </h5>
                          </div>
                        </div>
                        <hr className="line-breaker" />
                        <div>
                          {trackingExcelDetails.map((trackingData) => {
                            const { geoLocation, speciesExcelDetails } =
                              trackingData;
                            return (
                              <div className="tracking-card">
                                <div className="tracking-card-inner-text">
                                  <b>GeoLocation : </b>
                                  {geoLocation}
                                </div>
                                <div>
                                  <table className="tracking-card-table">
                                    <thead>
                                      <tr>
                                        <th className="th-height-species">
                                          Species Name
                                        </th>
                                        <th className="th-height-species-count">
                                          Species Count
                                        </th>
                                        <th className="th-height-girth">
                                          Height
                                        </th>
                                        <th className="th-height-girth">
                                          Girth
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {speciesExcelDetails?.map(
                                        (speciedData) => {
                                          const {
                                            speciesName,
                                            speciesCount,
                                            height,
                                            girth,
                                          } = speciedData;
                                          return (
                                            <tr>
                                              <td className="tr-data">
                                                {speciesName}
                                              </td>
                                              <td className="tr-data">
                                                {speciesCount}
                                              </td>
                                              <td className="tr-data">
                                                {height}
                                              </td>
                                              <td className="tr-data">
                                                {girth}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }
                )}
              {!showErrorBox && showPlantationDetails && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <button
                    className="btn btn-viewmore save-width"
                    style={{ float: "right" }}
                    onClick={() => postPlantationDetails()}
                  >
                    Proceed
                  </button>
                </div>
              )}
              {showErrorBox && (
                <div
                  style={{
                    border: "2px solid Red",
                    padding: "10px",
                    boxShadow: "0px 6px 9px -5px grey",
                    borderRadius: "4px",
                    marginTop: "8px",
                    color: "red",
                  }}
                >
                  {errorMsg?.map((item) => {
                    return <p>* {item}</p>;
                  })}
                  {/* * {errorMsg} */}
                </div>
              )}
            </div>
          </div>
          <Modal show={loading} centered className="modal-con">
            <div className="modal-con">
              <Spinner style={{ textAlign: "center" }} animation="border" />
            </div>
          </Modal>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            newestOnTop={false}
            hideProgressBar
            closeOnClick
            rtl={false}
          />
        </>
      )}
    </>
  );
}
